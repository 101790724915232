<style lang="scss">
  .update-product-modal{
    &__roles{
      max-height: 200px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div class="update-issue-modal">
    <section class="mb-2">
      <b-form-group
        label="Note"
        label-for="update-product-note"
      >
        <b-form-textarea
          id="update-product-note"
          v-model="issue.note"
          required
          class="mb-1"
          placeholder="Enter Note*"
          rows="3"
        />
      </b-form-group>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-1" :class="{'disabled': !checkFormValid()}"
                variant="success" @click="addNote()"
      >Add Note</b-button>
    </section>

  </div>
</template>

<script>
  import NotesService from '@/services/NotesService'

  export default {
    components: {
    },
    props: {
      issueExisting: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        issue: {
          ignored: '',
          closed: '',
          status: '',
          description: '',
          note: '',
        },
      };
    },
    mounted() {
      this.setIssueFromExisting();
    },
    methods: {
      setIssueFromExisting() {
        const {
          closed, description, ignored, note, subject, status,
        } = this.issueExisting;
        const productProduct = {
          closed, description, ignored, note, subject, status,
        };
        this.issue = productProduct;
      },
      closeModals() {
        this.$bvModal.hide('modal-update-issue');
        this.$bvModal.hide('modal-add-note');
      },
      addNote() {
        NotesService.createNote({ note: this.issue.note, model_type: 'Issue', model_uid: this.issueExisting.uid }).then(() => {
          this.$toast.success('Note Added Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh', true);
          this.closeModals();
        }).catch(() => {
          this.$toast.error('Could not add note, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        if (typeof this.issue.note !== 'undefined') {
          const note = true;
          return note;
        }
        return false;
      },
    },
  };
</script>
