var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"issues-page"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-xl-12"},[_c('div',{staticClass:"card card-congratulation-medal"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-right",staticStyle:{"float":"right"}},[_c('div',{staticClass:"ui-spacer"}),(_vm.issue.status !== 'ignored')?_c('b-button',{staticClass:"mr-1",class:{'disabled': !_vm.checkFormValid()},attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.ignoreIssue()}}},[_vm._v("Ignore Issue")]):_vm._e(),(_vm.issue.status === 'ignored')?_c('b-button',{staticClass:"mr-1",class:{'disabled': !_vm.checkFormValid()},attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.unIgnoreIssue()}}},[_vm._v("Un-Ignore Issue")]):_vm._e(),(_vm.issue.status !== 'closed')?_c('b-button',{staticClass:"mr-1",class:{'disabled': !_vm.checkFormValid()},attrs:{"variant":"danger"},on:{"click":function($event){return _vm.closeIssue()}}},[_vm._v("Close Issue")]):_vm._e(),(_vm.issue.status === 'closed')?_c('b-button',{staticClass:"mr-1",class:{'disabled': !_vm.checkFormValid()},attrs:{"variant":"success"},on:{"click":function($event){return _vm.openIssue()}}},[_vm._v("Re-Open Issue")]):_vm._e()],1),_c('p',{staticClass:"card-text font-small-3",staticStyle:{"float":"right"}},[(_vm.issue.ignored === true)?_c('feather-icon',{attrs:{"icon":"EyeOffIcon","size":"22"}}):_vm._e()],1),_c('h5',[_vm._v(_vm._s(_vm.issue.subject)+" | Severity "),_c('span',{staticClass:"badge badge-pill",class:{
            'badge-primary' : _vm.issue.severity === 'LOW',
            'badge-warning' : _vm.issue.severity === 'MEDIUM',
            'badge-danger' : _vm.issue.severity === 'HIGH',
            'badge-dark' : _vm.issue.severity === 'CRITICAL',
          }},[_vm._v(" "+_vm._s(_vm.issue.severity)+" ")])]),_c('h5',[_vm._v("Status "),_c('span',{staticClass:"badge badge-pill",class:{
            'badge-danger' : _vm.issue.status === 'open',
            'badge-warning' : _vm.issue.status === 'ignored',
            'badge-success' : _vm.issue.status === 'closed',
          }},[_vm._v(" "+_vm._s(_vm.issue.status)+" ")])]),(_vm.cve.length)?_c('div',[_vm._l((_vm.cve.references[0].tags.split(',')),function(item){return (_vm.cve.references)?_c('span',[_c('span',{staticClass:"badge badge-pill badge-primary"},[_vm._v(" "+_vm._s(item)+" ")])]):_vm._e()}),(_vm.cve.descriptions && _vm.cve.descriptions[0].value)?_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.cve.descriptions[0].value))]):_vm._e(),_c('p',[_vm._v("For further details refer to:")]),_c('p',[(_vm.cve.references && _vm.cve.references[0].link)?_c('a',{attrs:{"href":_vm.cve.references[0].link}},[_vm._v(_vm._s(_vm.cve.references[0].link))]):_vm._e()]),_c('div',{staticClass:"alert alert-info p-1"},[_vm._v("Issues will automatically update if the product version number is changed. You can manually close/ignore issues above or update the product version.")])],2):_vm._e(),(_vm.vulnerabilityScan.summary)?_c('div',[_c('p',[_vm._v(" Target: "+_vm._s(_vm.vulnerabilityScan.target_name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.vulnerabilityScan.summary)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.vulnerabilityScan.specific_result)+" ")]),_c('p',[_vm._v(" Solution Type: "+_vm._s(_vm.vulnerabilityScan.solution_type)+" ")]),_c('p',[_vm._v(" Solution: "+_vm._s(_vm.vulnerabilityScan.solution)+" ")]),_c('p',[_vm._v(" Detection Method: "+_vm._s(_vm.vulnerabilityScan.vulnerability_detection_method)+" ")]),_c('p',[_vm._v(" Vulnerability Insight: "+_vm._s(_vm.vulnerabilityScan.vulnerability_insight)+" ")])]):_vm._e()])])])]),_vm._m(0),_c('model-notes',{attrs:{"no-body":true,"model":"issue","uid":_vm.uid}}),_c('model-activities',{attrs:{"no-body":true,"model":"issue","uid":_vm.uid}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"ui-spacer"})])}]

export { render, staticRenderFns }