import axios from '@axios';

const baseUrl = '/cves';

export default {

  getCve(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
};
