import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/vulnerability_scan_results';

export default {
  getScanResults(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getScanResult(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
};
