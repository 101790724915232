<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="issues-page">

    <div class="row">
      <div class="col-md-6 col-xl-12">
        <div class="card card-congratulation-medal">
          <div class="card-body">
            <div class="text-right" style="float: right"  >
              <div class="ui-spacer" />
              <b-button v-if="issue.status !== 'ignored'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                        variant="secondary" @click="ignoreIssue()"
              >Ignore Issue</b-button>
              <b-button v-if="issue.status === 'ignored'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                        variant="secondary" @click="unIgnoreIssue()"
              >Un-Ignore Issue</b-button>
              <b-button v-if="issue.status !== 'closed'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                        variant="danger" @click="closeIssue()"
              >Close Issue</b-button>
              <b-button v-if="issue.status === 'closed'" class="mr-1" :class="{'disabled': !checkFormValid()}"
                        variant="success" @click="openIssue()"
              >Re-Open Issue</b-button>
            </div>
            <p style="float:right;" class="card-text font-small-3">
              <feather-icon
                v-if="issue.ignored === true"
                icon="EyeOffIcon"
                size="22"
              />
            </p>
            <h5>{{ issue.subject }} | Severity  <span class="badge badge-pill"
                                                     :class="{
              'badge-primary' : issue.severity === 'LOW',
              'badge-warning' : issue.severity === 'MEDIUM',
              'badge-danger' : issue.severity === 'HIGH',
              'badge-dark' : issue.severity === 'CRITICAL',
            }">
              {{ issue.severity }}
            </span></h5>
            <h5>Status  <span class="badge badge-pill"
                                                     :class="{
              'badge-danger' : issue.status === 'open',
              'badge-warning' : issue.status === 'ignored',
              'badge-success' : issue.status === 'closed',
            }">
              {{ issue.status }}
            </span></h5>
            <div v-if="cve.length">
              <span v-if="cve.references" v-for="item in cve.references[0].tags.split(',')">

                <span class="badge badge-pill badge-primary">
                {{ item }}
                </span>
                 &nbsp;
              </span>
              <p class="mt-2" v-if="cve.descriptions && cve.descriptions[0].value">{{ cve.descriptions[0].value }}</p>

              <p>For further details refer to:</p>
              <p>
                <a v-if="cve.references && cve.references[0].link" :href="cve.references[0].link">{{ cve.references[0].link }}</a>
              </p>
              <div class="alert alert-info p-1">Issues will automatically update if the product version number is changed. You can manually close/ignore issues above or update the product version.</div>
            </div>
            <div v-if="vulnerabilityScan.summary">
              <p>
                Target:  {{ vulnerabilityScan.target_name }}
              </p>

              <p>
                {{ vulnerabilityScan.summary }}
              </p>
              <p>
                {{ vulnerabilityScan.specific_result }}
              </p>
              <p>
                Solution Type: {{ vulnerabilityScan.solution_type }}
              </p>
              <p>
                Solution: {{ vulnerabilityScan.solution }}
              </p>
              <p>
                Detection Method: {{ vulnerabilityScan.vulnerability_detection_method }}
              </p>
              <p>
                Vulnerability Insight: {{ vulnerabilityScan.vulnerability_insight }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="ui-spacer"></div>
    </div>
    <model-notes :no-body="true" model="issue" :uid="uid"></model-notes>
    <model-activities :no-body="true" model="issue" :uid="uid"></model-activities>
  </div>
</template>
<!--eslint-enable-->

<script>
import VulnerabilityScanResultService from '@/services/VulnerabilityScanResultService';
import CveService from '@/services/CveService';
import IssueService from '../../services/IssueService';
import ModelActivities from '../activities/ModelActivities.vue';
import ModelNotes from '../notes/ModelNotes.vue';

export default {
  name: 'Issue',
  components: {
    ModelActivities,
    ModelNotes,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      issue: {},
      cve: {},
      vulnerabilityScan: {},
    };
  },
  mounted() {
    this.getIssue();
  },
  methods: {
    closeModals() {
      this.$bvModal.hide('modal-add-product');
    },
    getIssue() {
      this.loading = true;
      IssueService.getIssue(this.uid).then(res => {
        this.issue = res.data;
        this.getLinked();
      }).catch(() => {
        this.$toast.error('Could not get issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    showIssueModal(issue, modalName) {
      this.$bvModal.show(modalName);
    },
    getLinked() {
      this.loading = true;
      if (this.issue.issue_type === 'CVE' || this.issue.issue_type === 'NVD') {
        CveService.getCve(this.issue.issue_uid)
          .then(res => {
            this.cve = res.data;
          })
          .catch(() => {
            this.$toast.error('Could not get issue, please refresh and try again', {
              toastClassName: ['toast-std', 'warning-toast'],
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.issue.issue_type === 'VulnerabilityScanResult' || this.issue.issue_type === 'Scan') {
        VulnerabilityScanResultService.getScanResult(this.issue.issue_uid)
          .then(res => {
            this.vulnerabilityScan = res.data;
          })
          .catch(() => {
            this.$toast.error('Could not get scanResult, please refresh and try again', {
              toastClassName: ['toast-std', 'warning-toast'],
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    checkFormValid() {
      if (typeof this.issue.note !== 'undefined') {
        const note = true;
        return note;
      }
      return true;
    },
    ignoreIssue() {
      IssueService.patchIssue(this.issue.uid, { ignored: true, notes: this.issue.note }).then(() => {
        this.$toast.success('Ignored issue Successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.closeModals();
        this.issue.status = 'ignored';
        this.$emit('refresh', true);
      }).catch(() => {
        this.$toast.error('Could not ignore issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    unIgnoreIssue() {
      IssueService.patchIssue(this.issue.uid, { ignored: false, notes: this.issue.note }).then(() => {
        this.$toast.success('Ignored issue Successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.issue.status = 'open';
        this.$emit('refresh', true);
      }).catch(() => {
        this.$toast.error('Could not ignore issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    closeIssue() {
      IssueService.patchIssue(this.issue.uid, { closed: true, notes: this.issue.note }).then(() => {
        this.$toast.success('Closed issue Successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.issue.status = 'closed';
        this.$emit('refresh', true);
        this.closeModals();
      }).catch(() => {
        this.$toast.error('Could not close issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    openIssue() {
      IssueService.patchIssue(this.issue.uid, { closed: false, notes: this.issue.note }).then(() => {
        this.$toast.success('Re Opened issue Successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.issue.status = 'open';
        this.$emit('refresh', true);
        this.closeModals();
      }).catch(() => {
        this.$toast.error('Could not close issue, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
  },
};
</script>
