<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Activity')" :class="{'disabled': loading}">
    <b-card-actions action-refresh action-collapse @refresh="getMoreNotes(1)" title="Notes" ref="refreshCard" no-body>
      <b-card-body>
        <b-button class="btn mb-1 btn-success" style="float: right;" v-if="$can('Update', 'Issue')"
                                @click="showNoteModal(issue, 'modal-add-note')">Add Note
        </b-button>
        <b-card-sub-title>This table shows the notes attached to this resource</b-card-sub-title>
      </b-card-body>
      <section>
        <b-table
          class="data-table"
          hover
          :items="note"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>
          <template #cell(note)="data">
             <span style="white-space: pre-line">
              {{ data.item.description}}
              </span>
          </template>
          <template #cell(user)="data">
            <router-link :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.user.name }}
            </router-link>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreNotes" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 mx-2 my-1">No records</p>
        </b-row>
      </section>
    </b-card-actions>
    <b-modal id="modal-add-note" title="Add Note" size="lg" hide-footer>
      <add-note v-if="issue" :uid="issue.uid" :issue-existing="issue"  @refresh="getMoreNotes()" @close="closeModals()"></add-note>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import NotesService from '@/services/NotesService'
  import IssueService from '@/services/IssueService'
  import AddNote from '../issues/sections/AddNote.vue'

  export default {
    name: 'ModelNotes',
    components: {
      BCardActions,
      AddNote,
    },
    props: {
      title: {
        required: false,
        type: String,
        default: 'Notes',
      },
      model: {
        required: true,
        type: String,
      },
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        loading: false,
        issue: {},
        headers: [
          {
            key: 'created_at',
            label: 'Date',
          },
          {
            key: 'note',
            label: 'Note',
          },
          {
            key: 'user',
            label: 'Added By',
          },
        ],
        note: [],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
        },
        total_count: 0,
      };
    },
    mounted() {
        this.getNotes();
        this.setIssue();
    },
    methods: {
      setIssue() {
        IssueService.getIssue(this.uid).then(res => {
          this.issue = res.data;
        }).catch(() => {
            this.$toast.error('Could not get note, please refresh and try again', {
              toastClassName: ['toast-std', 'warning-toast'],
            });
        }).finally(() => {
          setTimeout(() => {
            this.$refs.refreshCard.showLoading = false
          }, 100)
        });
      },
      showNoteModal(issue, modalName) {
        this.$bvModal.show(modalName);
      },
      getMoreNotes(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getNotes();
      },
      getNotes() {
        this.$refs.refreshCard.showLoading = true
        NotesService.getModelNotes(this.filters, this.model, this.uid).then(res => {
          this.note = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(() => {
          this.$toast.error('Could not get notes, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        }).finally(() => {
          setTimeout(() => {
            this.$refs.refreshCard.showLoading = false
          }, 100)
        });
      },
    },
  };
</script>
