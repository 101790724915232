import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/notes';

export default {
  getModelNotes(filters, model, uid) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${model}/${uid}${queryString}`);
  },
  createNote(note) {
    return axios.post(`${baseUrl}`, note);
  },
};
